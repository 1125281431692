import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { createContext, useCallback, useReducer } from 'react';

// ==============================|| KhoraSetup CONTEXT & PROVIDER ||============================== //

const initialState = {
  isInitialized: false
};
const KhoraSetupReducer = (state) => {
  return state;
};
const KhoraSetupContext = createContext(null);

export const KhoraSetupProvider = ({ children }) => {
  const [state] = useReducer(KhoraSetupReducer, initialState);

  const getGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/main');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteGrowingPlan = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/growing_plan/main/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getResourceSeedList = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getResourceSeed = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/seed/detail/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getResourceMushroomList = useCallback(async () => {
    try {
      const res = await axios.get('/list/mushroom');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getResourceMushroom = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/mushroom/detail/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getResourceMicrogreenList = useCallback(async () => {
    try {
      const res = await axios.get('/list/microgreen');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getResourceMicrogreen = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/microgreen/detail/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPlantVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_variety');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setPlantVariety = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/plant_variety', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePlantVariety = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/plant_variety/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePlantVariety = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/plant_variety/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMushroomVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/mushroom_variety');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setMushroomVariety = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/mushroom_variety', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateMushroomVariety = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/mushroom_variety/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteMushroomVariety = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/mushroom_variety/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getFruitVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/fruit_variety');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setFruitVariety = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/fruit_variety', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateFruitVariety = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/fruit_variety/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteFruitVariety = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/fruit_variety/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPlantType = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setPlantType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/plant_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePlantType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/plant_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePlantType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/plant_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getSoilType = useCallback(async () => {
    try {
      const res = await axios.get('/type/soil_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createSoilType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/soil_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateSoilType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/soil_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteSoilType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/soil_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getSoil = useCallback(async () => {
    try {
      const res = await axios.get('/type/soil');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createSoil = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/soil', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateSoil = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/soil/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteSoil = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/soil/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingStepTask = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createGrowingStepTask = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/growing_task', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateGrowingStepTask = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/growing_task/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteGrowingStepTask = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/growing_task/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingStepTaskType = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createGrowingStepTaskType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/growing_task_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateGrowingStepTaskType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/growing_task_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteGrowingStepTaskType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/growing_task_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPhPreference = useCallback(async () => {
    try {
      const res = await axios.get('/growing_item/ph_level');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const getMeasure = useCallback(async (type = 'mass') => {
    try {
      const res = await axios.get(`/type/measure/${type}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const getAllMeasure = useCallback(async () => {
    try {
      const res = await axios.get('/type/measurement');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const getItemsByStep = useCallback(async (Step) => {
    try {
      const res = await axios.get('/growing_item/growing_task/' + Step);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const getZoneCountry = useCallback(async () => {
    try {
      const res = await axios.get('/type/zone_country');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const getZones = useCallback(async () => {
    try {
      const res = await axios.get('/growing_item/zone');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setResourceSeed = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/seed', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const updateResourceSeed = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/seed/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err.message };
    }
  }, []);

  const deleteResourceSeed = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/seed/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setResourceMushroom = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/mushroom', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const updateResourceMushroom = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/mushroom/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err.message };
    }
  }, []);

  const deleteResourceMushroom = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/mushroom/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setResourceMicrogreen = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/microgreen', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const updateResourceMicrogreen = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/microgreen/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err.message };
    }
  }, []);

  const deleteResourceMicrogreen = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/microgreen/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const saveGrowingStep = useCallback(async (values) => {
    try {
      const res = await axios.put('/growing_plan/growing/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateGrowingStep = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/growing_plan/growing/1/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const getGrowingStep = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/growing/1');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);
  const deleteGrowingStep = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/growing_plan/growing/1/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getContainerData = useCallback(async () => {
    try {
      const res = await axios.get('/type/container/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getSoilMediumData = useCallback(async () => {
    try {
      const res = await axios.get('/type/soil/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getEnhancerData = useCallback(async () => {
    try {
      const res = await axios.get('/type/enhancer/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTaskSteps = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTaskData = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // get current creating public growing plan
  const getCurrentPlant = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/temp/one/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const savePlantInformation = useCallback(async (PlantID, is_seed) => {
    try {
      const seed_id = is_seed ? PlantID : null;
      const cutting_id = !is_seed ? PlantID : null;
      await axios.put('/growing_plan/main', { seed_id, cutting_id, is_seed });
      return true;
    } catch (err) {
      return err?.message;
    }
  }, []);

  const savePlantInformationForUpdate = useCallback(async (id, title, urgent_comm, description) => {
    try {
      await axios.post('/growing_plan/main/' + id, { title, urgent_comm, description });
      return true;
    } catch (err) {
      return err?.message;
    }
  }, []);

  const getSupplierNames = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed/vendor');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const saveTransPlantStep = useCallback(async (values) => {
    try {
      const res = await axios.put('/growing_plan/transplant/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateTransPlantStep = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/growing_plan/transplant/1/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getTransPlantStep = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/transplant/1');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteTransPlantStep = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/growing_plan/transplant/1/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGraftingType = useCallback(async () => {
    try {
      const res = await axios.get('/type/grafting');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // get all detailed public cutting data
  const getCuttingSummary = useCallback(async () => {
    try {
      const res = await axios.get('/list/cutting');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // get all public cutting data
  const getResourceCuttingList = useCallback(async () => {
    try {
      const res = await axios.get('/list/cutting');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setResourceCutting = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/cutting', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getResourceCutting = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/cutting/detail/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateResourceCutting = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/cutting/${ID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteResourceCutting = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/cutting/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getVendorType = useCallback(async () => {
    try {
      const res = await axios.get('/type/vendor_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setVendorType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/vendor_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateVendorType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/vendor_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteVendorType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/vendor_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getCostCategory = useCallback(async (ctype, stype) => {
    try {
      const res = await axios.get(`/type/cost_category/${ctype}/${stype}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setCostCategory = useCallback(async (ctype, stype, values) => {
    try {
      const res = await axios.put(`/type/cost_category/${ctype}/${stype}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateCostCategory = useCallback(async (ctype, stype, id, values) => {
    try {
      const res = await axios.post(`/type/cost_category/${ctype}/${stype}/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteCostCategory = useCallback(async (ctype, stype, id) => {
    try {
      const res = await axios.delete(`/type/cost_category/${ctype}/${stype}/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getProductTypeName = useCallback(async () => {
    try {
      const res = await axios.get('/type/producttype');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setProductTypeName = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/producttype', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateProductTypeName = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/producttype/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteProductTypeName = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/producttype/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setVendor = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/vendor', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getVendorList = useCallback(async () => {
    try {
      const res = await axios.get('/list/vendor');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getVendor = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/vendor/one/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateVendor = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/vendor/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteVendor = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/vendor/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getIrrigationPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/irrigation');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setIrrigationPlan = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/irrigation', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getIrrigationPlan = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/irrigation/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateIrrigationPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/irrigation/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteIrrigationPlan = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/irrigation/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setIrrigationPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/irrigation/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateIrrigationPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/irrigation/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getIrrigationPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/irrigation/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteIrrigationPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.delete('/growing_plan/irrigation/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnvironmentPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/environment');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setEnvironmentPlan = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/environment', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnvironmentPlan = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/environment/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateEnvironmentPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/environment/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnvironmentPlan = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/environment/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setEnvironmentPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/environment/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateEnvironmentPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/environment/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnvironmentPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/environment/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnvironmentPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.delete('/growing_plan/environment/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getLightingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/lighting');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setLightingPlan = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/lighting', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getLightingPlan = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/lighting/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateLightingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/lighting/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteLightingPlan = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/lighting/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const setLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/lighting/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/lighting/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/lighting/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.delete('/growing_plan/lighting/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNewLightingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/new_lighting');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setNewLightingPlan = useCallback(async (values) => {
    try {
      const res = await axios.put('/list/new_lighting', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNewLightingPlan = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/new_lighting/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNewLightingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/list/new_lighting/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNewLightingPlan = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/list/new_lighting/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const setNewLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/new_lighting/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNewLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/new_lighting/1', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNewLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/new_lighting/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNewLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.delete('/growing_plan/new_lighting/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getCurrentGrowingPlanStatus = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/temp/status/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const finalizeGrowingPlan = useCallback(async (title, urgent_comm, description) => {
    try {
      const res = await axios.post('/growing_plan/save', { title, urgent_comm, description });
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateGrowingPlan = useCallback(async (planId) => {
    try {
      const res = await axios.post('/growing_plan/update/' + planId);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealPlantInformation = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/temp/one/2');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: [] };
    }
  }, []);

  const saveRealTransPlantStep = useCallback(async (values) => {
    try {
      const res = await axios.put('/growing_plan/transplant/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealTransPlantStep = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/growing_plan/transplant/2/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealTransPlantStep = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/transplant/2');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteRealTransPlantStep = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/growing_plan/transplant/2/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const loadRealToTemp = useCallback(async (id) => {
    try {
      const res = await axios.post(`/growing_plan/load/${id}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const saveRealGrowingStep = useCallback(async (values) => {
    try {
      const res = await axios.put('/growing_plan/growing/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealGrowingStep = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/growing_plan/growing/2/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);
  const getRealGrowingStep = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/growing/2');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteRealGrowingStep = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/growing_plan/growing/2/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/lighting/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/lighting/2');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealNewLightingPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/new_lighting/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealNewLightingPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/new_lighting/2');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealIrrigationPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/irrigation/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealIrrigationPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/irrigation/2');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateRealEnvironmentPlanForGrowingPlan = useCallback(async (values) => {
    try {
      const res = await axios.post('/growing_plan/environment/2', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getRealEnvironmentPlanForGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/environment/2');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get all detailed public seed data
  const getSeedSummary = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMushroomSummary = useCallback(async () => {
    try {
      const res = await axios.get('/list/mushroom');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMicrogreenSummary = useCallback(async () => {
    try {
      const res = await axios.get('/list/microgreen');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getContainerType = useCallback(async () => {
    try {
      const res = await axios.get('/type/container_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createContainerType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/container_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateContainerType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/container_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteContainerType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/container_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getContainer = useCallback(async () => {
    try {
      const res = await axios.get('/type/container');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createContainer = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/container', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateContainer = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/container/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteContainer = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/container/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnhancerType = useCallback(async () => {
    try {
      const res = await axios.get('/type/enhancer_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createEnhancerType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/enhancer_type', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateEnhancerType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/enhancer_type/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnhancerType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/enhancer_type/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnhancer = useCallback(async () => {
    try {
      const res = await axios.get('/type/enhancer');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createEnhancer = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/enhancer', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateEnhancer = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/enhancer/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnhancer = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/enhancer/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getEnzymeType = useCallback(async () => {
    try {
      const res = await axios.get('/type/enzyme');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setEnzymeType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/enzyme', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateEnzymeType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/enzyme/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnzymeType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/enzyme/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getFertilizerType = useCallback(async () => {
    try {
      const res = await axios.get('/type/fertilizer');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setFertilizerType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/fertilizer', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateFertilizerType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/fertilizer/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteFertilizerType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/fertilizer/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNutrientType = useCallback(async () => {
    try {
      const res = await axios.get('/type/nutrient');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setNutrientType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/nutrient', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNutrientType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/nutrient/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNutrientType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/nutrient/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPredatorInsectType = useCallback(async () => {
    try {
      const res = await axios.get('/type/predator_insect');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setPredatorInsectType = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/predator_insect', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePredatorInsectType = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/predator_insect/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePredatorInsectType = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/predator_insect/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getUserList = useCallback(async () => {
    try {
      const res = await axios.get('/user/main');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteUser = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/user/main/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const enableUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/user/main/enable/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const disableUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/user/main/disable/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPrivateListsCount = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/main/private_counts/${ID}`);
      return res.data;
    } catch (err) {
      return {
        seed: 0,
        mushroom: 0,
        cutting: 0,
        vendor: 0,
        irrigation: 0,
        lighting: 0,
        new_lighting: 0,
        environment: 0,
        growingplan: 0
      };
    }
  }, []);

  const getSeedListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/seed/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMicrogreenListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/microgreen/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMushroomListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/mushroom/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCuttingListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/cutting/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getLightingListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/lighting/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getNewLightingListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/new_lighting/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getEnvironmentListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/environment/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getIrrigationListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/irrigation/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getVendorListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/list/vendor/private/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingPlanListByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/growing_plan/main/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingStrategyByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/user/main/grow_strategy/' + ID);
      return res.data;
    } catch (err) {
      return {
        GrowWhat: 0,
        GrowPrefer: 0,
        GetCubesHow: 0,
        GrowAuto: 0,
        GrowInterest: 0,
        PeopleCount: 0
      };
    }
  }, []);

  const getAddressByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/user/address/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/user/main/' + ID);
      return res.data;
    } catch (err) {
      return {
        name: '',
        email: '',
        phone_number: '',
        role: 1,
        membership: 1,
        login_type: 1,
        active: 1
      };
    }
  }, []);

  const getLocationByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/user/location/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMembershipHistoryByUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/user/membership/history/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getResourceSeedAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/seed/detail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getResourceMicrogreenAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/microgreen/detail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getResourceCuttingAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/cutting/detail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getResourceMushroomAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/list/mushroom/detail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getEnvironmentPlanAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/environmentdetail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getIrrigationPlanAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/irrigationdetail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getLightingPlanAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/lightingdetail/${ID}`);
      return res.data;
    } catch (err) {
      return { data: [], info: { Note: '', nickname: '', FarmTitle: '' } };
    }
  }, []);

  const getVendorAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/vendordetail/${ID}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const getGrowingPlanBasicAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/main/one/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingPlanTransplantAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/transplant/private/${ID}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingPlanGrowingStepAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/growing/private/${ID}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingPlanLightingAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/lighting/private/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingPlanNewLightingAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/new_lighting/private/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingPlanEnvironmentAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/environment/private/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingPlanIrrigationAdmin = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/growing_plan/irrigation/private/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPrivateCropPlansCount = useCallback(async (ID) => {
    try {
      const res = await axios.get(`/user/main/crop_plan_counts/${ID}`);
      return res.data;
    } catch (err) {
      return {
        auto: 0,
        advanced: 0,
        manual: 0
      };
    }
  }, []);

  const getAllAutoCropPlansByUser = useCallback(async (userId) => {
    try {
      const res = await axios.get(`/crop_plan/auto/${userId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllAdvancedCropPlansByUser = useCallback(async (userId) => {
    try {
      const res = await axios.get(`/crop_plan/advanced/${userId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllManualCropPlansByUser = useCallback(async (userId) => {
    try {
      const res = await axios.get(`/crop_plan/manual/${userId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const saveObservationNote = useCallback(async (values) => {
    try {
      const res = await axios.put('/observation', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getObservationNoteList = useCallback(async () => {
    try {
      const res = await axios.get('/observation');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // const updateObservationNote = useCallback(async (ID, values) => {
  //   try {
  //     const res = await axios.post('/observation/' + ID, values);
  //     return { status: true, data: res.data };
  //   } catch (err) {
  //     return { status: false, data: err?.message };
  //   }
  // }, []);

  // const deleteObservationNote = useCallback(async (ID) => {
  //   try {
  //     const res = await axios.delete('/observation/' + ID);
  //     return { status: true, data: res.data };
  //   } catch (err) {
  //     return { status: false, data: err?.message };
  //   }
  // }, []);

  const saveObservationStep = useCallback(async (observationID, values) => {
    try {
      const res = await axios.put('/growing/observationsteps/' + observationID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getObservationStepList = useCallback(async (observationID) => {
    try {
      const res = await axios.get('/growing/observationsteps/' + observationID);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateObservationStep = useCallback(async (observationID, ID, values) => {
    try {
      const res = await axios.post('/growing/observationsteps/' + observationID + '/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteObservationStep = useCallback(async (observationID, ID) => {
    try {
      const res = await axios.delete('/growing/observationsteps/' + observationID + '/' + ID);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getOneObservationNote = async (ID) => {
    try {
      const res = await axios.get('/observation/' + ID);
      return res.data;
    } catch (err) {
      return [];
    }
  };

  const updateObservationNote = async (ID, values) => {
    try {
      const res = await axios.post('/observation/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  };

  const deleteObservationNote = async (ID) => {
    try {
      const res = await axios.delete('/observation/' + ID);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  };

  const saveObservationLog = async (observationID, type, values) => {
    try {
      const res = await axios.put('/observation/note/' + observationID + '/' + type, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  };

  const getObservationLogList = async (observationID, type) => {
    try {
      const res = await axios.get('/observation/note/' + observationID + '/' + type);
      return res.data;
    } catch (err) {
      return [];
    }
  };

  const updateObservationLog = async (observationID, type, logID, values) => {
    try {
      const res = await axios.post('/observation/note/' + observationID + '/' + type + '/' + logID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  };

  const deleteObservationLog = async (observationID, type, logID) => {
    try {
      const res = await axios.delete('/observation/note/' + observationID + '/' + type + '/' + logID);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  };

  const getSeedingGroup = useCallback(async () => {
    try {
      const res = await axios.get('/type/seeding_group');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setSeedingGroup = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/seeding_group', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateSeedingGroup = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/seeding_group/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteSeedingGroup = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/seeding_group/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPlantGroup = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_group');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setGrowingGroup = useCallback(async (values) => {
    try {
      const res = await axios.put('/type/plant_group', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateGrowingGroup = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/type/plant_group/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteGrowingGroup = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/type/plant_group/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPublishListsCount = useCallback(async () => {
    try {
      const res = await axios.get(`/list/publishcounts`);
      return res.data;
    } catch (err) {
      return {
        seed: 0,
        cutting: 0,
        vendor: 0,
        irrigation: 0,
        lighting: 0,
        environment: 0,
        growingplan: 0
      };
    }
  }, []);

  const getSeedPublishList = useCallback(async () => {
    try {
      const res = await axios.get(`/list/seedpublishlist`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const processSeedPublishRequest = useCallback(async (ID, accept) => {
    try {
      const res = accept ? await axios.post(`/list/seedpublishaccept/${ID}`) : await axios.post(`/list/seedpublishreject/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getCuttingPublishList = useCallback(async () => {
    try {
      // TODO - change endpoint
      const res = await axios.get(`/list/cuttingpublishlist`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingPlanPublishList = useCallback(async () => {
    try {
      const res = await axios.get(`/growing_plan/publishlist`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const processGrowingPlanPublishRequest = useCallback(async (ID, publish_status, publish_note = null) => {
    try {
      const res = await axios.post(`/growing_plan/publish/set_status/${ID}`, { publish_status, publish_note });
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneGrowingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/growing_plan/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneSeed = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/seed/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneMushroom = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/mushroom/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneMicrogreen = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/microgreen/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneCutting = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/cutting/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneLightingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/lighting/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneNewLightingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/new_lighting/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneEnvironmentPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/environment/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneIrrigationPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post('/list/irrigation/clone/' + ID, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const sendPrivateNotification = useCallback(async (values) => {
    try {
      await axios.post('/test/private_noti', values);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const sendPublicNotification = useCallback(async (values) => {
    try {
      await axios.post('/test/public_noti', values);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Announcement
  const getAllSystemNotification = useCallback(async () => {
    try {
      const res = await axios.get('/announcement');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createSystemNotification = useCallback(async (values) => {
    try {
      const res = await axios.put('/announcement', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createStandardSystemNotification = useCallback(async (values) => {
    try {
      const res = await axios.put('/announcement/standard', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateStandardSystemNotification = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/announcement/standard/${id}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const archiveSystemNotification = useCallback(async (ids) => {
    try {
      const res = await axios.post('/announcement/archive', { ids });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteArchivedNotifications = useCallback(async (ids) => {
    try {
      const res = await axios.delete('/announcement/archived', { data: { ids } });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const retractNotifications = useCallback(async (id) => {
    try {
      const res = await axios.post(`/announcement/retract/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const moveSystemNotificationToSent = useCallback(async (ids) => {
    try {
      const res = await axios.post('/announcement/sent', { ids });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const activeStandardNotification = useCallback(async (id) => {
    try {
      const res = await axios.post(`/announcement/standard/active/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const inactiveStandardNotification = useCallback(async (id) => {
    try {
      const res = await axios.post(`/announcement/standard/inactive/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const changeAnnouncementOrder = useCallback(async (dragID, dropID) => {
    try {
      const res = await axios.post(`/announcement/change_order/${dragID}/${dropID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Marketplace Announcement
  const marketGetAllSystemNotification = useCallback(async () => {
    try {
      const res = await axios.get('/market/announcement');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const marketCreateSystemNotification = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/announcement', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketCreateStandardSystemNotification = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/announcement/standard', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketUpdateStandardSystemNotification = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/announcement/standard/${id}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketArchiveSystemNotification = useCallback(async (ids) => {
    try {
      const res = await axios.post('/market/announcement/archive', { ids });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketDeleteArchivedNotifications = useCallback(async (ids) => {
    try {
      const res = await axios.delete('/market/announcement/archived', { data: { ids } });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketRetractNotifications = useCallback(async (id) => {
    try {
      const res = await axios.post(`/market/announcement/retract/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketMoveSystemNotificationToSent = useCallback(async (ids) => {
    try {
      const res = await axios.post('/market/announcement/sent', { ids });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketActiveStandardNotification = useCallback(async (id) => {
    try {
      const res = await axios.post(`/market/announcement/standard/active/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketInactiveStandardNotification = useCallback(async (id) => {
    try {
      const res = await axios.post(`/market/announcement/standard/inactive/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const marketChangeAnnouncementOrder = useCallback(async (dragID, dropID) => {
    try {
      const res = await axios.post(`/market/announcement/change_order/${dragID}/${dropID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateMembershipTrialDuration = useCallback(async (values) => {
    try {
      await axios.post(`/setting/trial_duration`, values);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMembershipTrialDuration = useCallback(async () => {
    try {
      const res = await axios.get(`/setting/trial_duration`);
      return res?.data?.trial_duration;
    } catch (err) {
      return 0;
    }
  }, []);

  // stripe product
  const getStripeProductList = useCallback(async () => {
    try {
      const res = await axios.get('/stripe/product');
      return res.data.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getStripeProduct = useCallback(async (id) => {
    try {
      const res = await axios.get(`/stripe/product/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createStripeProduct = useCallback(async (values) => {
    try {
      const res = await axios.put('/stripe/product', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateStripeProduct = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/stripe/product/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteStripeProduct = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/stripe/product/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // stripe tax rates
  const getStripeTaxRateList = useCallback(async () => {
    try {
      const res = await axios.get('/stripe/tax');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getStripeTaxRateHistory = useCallback(async () => {
    try {
      const res = await axios.get('/stripe/tax/history');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getStripeTaxRateByCategory = useCallback(async (params) => {
    try {
      const res = await axios.get('/stripe/tax/category', { params });
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createStripeTaxRate = useCallback(async (values) => {
    try {
      const res = await axios.put('/stripe/tax', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateStripeTaxRate = useCallback(async (values) => {
    try {
      const res = await axios.post('/stripe/tax', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteStripeTaxRate = useCallback(async (data) => {
    try {
      const res = await axios.delete('/stripe/product', data);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  //////////////////////
  // Marketplace APIs //
  //////////////////////

  const getMarketUserList = useCallback(async () => {
    try {
      const res = await axios.get('/market/user/main');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.delete(`/market/user/main/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const makeFMMUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/market/user/main/fmm/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const enableMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/market/user/main/enable/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const disableMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/market/user/main/disable/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const verifyMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/market/user/main/verify/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unverifyMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.post(`/market/user/main/unverify/${ID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateMarketSeller = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/market/user/seller/update/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateMarketFMM = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/market/user/fmm/update/${ID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMarketUser = useCallback(async (ID) => {
    try {
      const res = await axios.get('/market/user/main/' + ID);
      return res.data;
    } catch (err) {
      return {
        name: '',
        email: '',
        phone_number: '',
        role: 1,
        login_type: 1,
        active: 1
      };
    }
  }, []);

  const getMarketProductList = useCallback(async () => {
    try {
      const res = await axios.get('/market/product');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteMarketProduct = useCallback(async (productId) => {
    try {
      const res = await axios.delete(`/market/product/${productId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMarketTaxRateList = useCallback(async (date) => {
    try {
      const year = date?.getFullYear();
      const month = date?.getMonth() + 1;
      const res = await axios.get(`/market/tax_rate/${year}/${month}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateMarketTaxRate = useCallback(async (id, date, values) => {
    try {
      const year = date?.getFullYear();
      const month = date?.getMonth() + 1;
      const res = await axios.post(`/market/tax_rate/${year}/${month}/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const syncMarketTaxRate = useCallback(async (date) => {
    try {
      const year = date?.getFullYear();
      const month = date?.getMonth() + 1;
      const res = await axios.post(`/market/tax_rate/${year}/${month}/sync`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getMarketDefaultTaxRateList = useCallback(async () => {
    try {
      const res = await axios.get('/market/tax_rate_default');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const addMarketDefaultTaxRate = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/tax_rate_default', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateMarketDefaultTaxRate = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/tax_rate_default/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllMarketCategory = useCallback(async () => {
    try {
      const res = await axios.get('/market/product/category');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAllMarketOrderProducts = useCallback(async () => {
    try {
      const res = await axios.get('/market/order/products');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAllTaxPaymentHistory = useCallback(async () => {
    try {
      const res = await axios.get('/market/tax_pay_history');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createTaxPayment = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/tax_pay_history', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateTaxPayment = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/tax_pay_history/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteTaxPayment = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/market/tax_pay_history/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllTaxCertificateData = useCallback(async () => {
    try {
      const res = await axios.get('/market/tax_certificate');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createTaxCertificate = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/tax_certificate', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateTaxCertificate = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/tax_certificate/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllCashSaleData = useCallback(async () => {
    try {
      const res = await axios.get('/market/cash_sale');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createCashSale = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/cash_sale', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateCashSale = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/cash_sale/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteCashSale = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/market/cash_sale/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllCardProcessingData = useCallback(async () => {
    try {
      const res = await axios.get('/market/card_processing');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createCardProcessing = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/card_processing', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateCardProcessing = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/card_processing/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteCardProcessing = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/market/card_processing/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const importCardProcessingCsvData = useCallback(async (values) => {
    try {
      const res = await axios.post('/market/card_processing/import_csv', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get address information
  const getCountries = useCallback(async () => {
    try {
      const res = await axios.get('/market/address/country');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getStatesForCountry = useCallback(async (countryId) => {
    try {
      const res = await axios.get(`/market/address/state/${countryId}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCitiesForState = useCallback(async (stateId) => {
    try {
      const res = await axios.get(`/market/address/city/${stateId}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // vendor payment report
  const getAllMarketSellersWithOrderInfo = useCallback(async () => {
    try {
      const res = await axios.get('/market/order/seller_orders');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // detail info of a seller for vendor payment report
  const getDetailInfoForSeller = useCallback(async (id) => {
    try {
      const res = await axios.get(`/market/order/detail/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // vendor payment history
  const getAllVendorPaymentHistory = useCallback(async () => {
    try {
      const res = await axios.get('/market/vendor/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  // create vendor payment history
  const createVendorPayment = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/vendor', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // update vendor payment history
  const updateVendorPayment = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/market/vendor/${id}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get marketplace system setting
  const getMarketSystemSetting = useCallback(async () => {
    try {
      const res = await axios.get('/market/setting');
      return res?.data;
    } catch (err) {
      return null;
    }
  }, []);

  // cancel a seller order
  const cancelSellerOrder = useCallback(async (id) => {
    try {
      const res = await axios.post('/market/order/cancel_order/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get invoice detail by invoice number
  const getInvoiceDetailInfo = useCallback(async (invoiceNumber) => {
    try {
      const res = await axios.get('/market/order/invoice/' + invoiceNumber);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get all reviews
  const getAllProductReviews = useCallback(async () => {
    try {
      const res = await axios.get('/market/review/all');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  // delete a product review
  const deleteProductReview = useCallback(async (id) => {
    try {
      const res = await axios.delete('/market/review/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // delete a product reply
  const deleteProductReply = useCallback(async (id) => {
    try {
      const res = await axios.delete('/market/review/reply/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get all reviews for a product
  const getProductReviewsData = useCallback(async (productId) => {
    try {
      const res = await axios.get('/market/product/review/' + productId);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  // get top 10 products
  const getTop10Products = useCallback(async () => {
    try {
      const res = await axios.get('/market/product/top10');
      return res?.data;
    } catch (err) {
      return {};
    }
  }, []);

  // get stripe fee list
  const getStripeFeeList = useCallback(async () => {
    try {
      const res = await axios.get('/market/stripe_fee/all');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  // create stripe fee
  const createStripeFee = useCallback(async (values) => {
    try {
      const res = await axios.post('/market/stripe_fee', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // edit stripe fee
  const editStripeFee = useCallback(async (id, values) => {
    try {
      const res = await axios.post('/market/stripe_fee/' + id, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // delete stripe fee
  const deleteStripeFee = useCallback(async (id) => {
    try {
      const res = await axios.delete('/market/stripe_fee/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get marketplace commission list
  const getMarketplaceCommissionList = useCallback(async () => {
    try {
      const res = await axios.get('/market/marketplace_commission/all');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  // create marketplace commission
  const createMarketplaceCommission = useCallback(async (values) => {
    try {
      const res = await axios.post('/market/marketplace_commission', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // edit marketplace commission
  const editMarketplaceCommission = useCallback(async (id, values) => {
    try {
      const res = await axios.post('/market/marketplace_commission/' + id, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // delete marketplace commission
  const deleteMarketplaceCommission = useCallback(async (id) => {
    try {
      const res = await axios.delete('/market/marketplace_commission/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // get electronic fee tax list
  const getElectronicFeeTaxList = useCallback(async () => {
    try {
      const res = await axios.get('/market/electronic_fee_tax/all');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  // create electronic fee tax
  const createElectronicFeeTax = useCallback(async (values) => {
    try {
      const res = await axios.post('/market/electronic_fee_tax', values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // edit electronic fee tax
  const editElectronicFeeTax = useCallback(async (id, values) => {
    try {
      const res = await axios.post('/market/electronic_fee_tax/' + id, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // delete electronic fee tax
  const deleteElectronicFeeTax = useCallback(async (id) => {
    try {
      const res = await axios.delete('/market/electronic_fee_tax/' + id);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNewsList = useCallback(async () => {
    try {
      const res = await axios.get('/market/news/post');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createNews = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/news/post', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNews = useCallback(async (newsID, values) => {
    try {
      const res = await axios.post(`/market/news/post/${newsID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNews = useCallback(async (newsID) => {
    try {
      const res = await axios.delete(`/market/news/post/${newsID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getNewsCategoryList = useCallback(async () => {
    try {
      const res = await axios.get('/market/news/category');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createNewsCategory = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/news/category', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNewsCategory = useCallback(async (categoryID, values) => {
    try {
      const res = await axios.post(`/market/news/category/${categoryID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNewsCategory = useCallback(async (categoryID) => {
    try {
      const res = await axios.delete(`/market/news/category/${categoryID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateUserDiscount = useCallback(async (userID, values) => {
    try {
      const res = await axios.post(`/user/main/discount/${userID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllInvestors = useCallback(async () => {
    try {
      const res = await axios.get('/market/news/investor');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createInvestor = useCallback(async (values) => {
    try {
      const res = await axios.put('/market/news/investor', values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateInvestor = useCallback(async (investorID, values) => {
    try {
      const res = await axios.post(`/market/news/investor/${investorID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteInvestor = useCallback(async (investorID) => {
    try {
      const res = await axios.delete(`/market/news/investor/${investorID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getAllFarmersMarkets = useCallback(async () => {
    try {
      const res = await axios.get('/market/farmers_market');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const deleteFarmersMarket = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/market/farmers_market/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const enableFarmersMarket = useCallback(async (id) => {
    try {
      const res = await axios.post(`/market/farmers_market/enable/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const disableFarmersMarket = useCallback(async (id) => {
    try {
      const res = await axios.post(`/market/farmers_market/disable/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createReviewerNotes = useCallback(async (userID, values) => {
    try {
      const res = await axios.put(`/market/user/seller/reviewer_note/${userID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateReviewerNotes = useCallback(async (userID, noteID, values) => {
    try {
      const res = await axios.post(`/market/user/seller/reviewer_note/${userID}/${noteID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteReviewerNotes = useCallback(async (userID, noteID) => {
    try {
      const res = await axios.delete(`/market/user/seller/reviewer_note/${userID}/${noteID}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const downloadMarketplaceNotiAttachment = useCallback(async (attachUrl, filename) => {
    try {
      const res = await axios.get(`/market/announcement/attachment?url=${encodeURIComponent(attachUrl)}`, { responseType: 'blob' });
      const url = URL.createObjectURL(res.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // history of sales
  const getAllHistorySales = useCallback(async () => {
    try {
      const res = await axios.get('/sales');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateHistorySale = useCallback(async (saleID, values) => {
    try {
      const res = await axios.post(`/sales/${saleID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  return (
    <KhoraSetupContext.Provider
      value={{
        ...state,
        getResourceSeedList,
        getResourceSeed,
        getResourceMushroomList,
        getResourceMushroom,
        getResourceMicrogreenList,
        getResourceMicrogreen,
        getPlantVariety,
        setPlantVariety,
        updatePlantVariety,
        deletePlantVariety,
        getMushroomVariety,
        setMushroomVariety,
        updateMushroomVariety,
        deleteMushroomVariety,
        getFruitVariety,
        setFruitVariety,
        updateFruitVariety,
        deleteFruitVariety,
        getPlantType,
        setPlantType,
        updatePlantType,
        deletePlantType,
        getSoilType,
        createSoilType,
        updateSoilType,
        deleteSoilType,
        getSoil,
        createSoil,
        updateSoil,
        deleteSoil,
        getGrowingStepTask,
        createGrowingStepTask,
        updateGrowingStepTask,
        deleteGrowingStepTask,
        getGrowingStepTaskType,
        createGrowingStepTaskType,
        updateGrowingStepTaskType,
        deleteGrowingStepTaskType,
        getPhPreference,
        getMeasure,
        getAllMeasure,
        getItemsByStep,
        getZoneCountry,
        getZones,
        setResourceSeed,
        deleteResourceSeed,
        updateResourceSeed,
        setResourceMushroom,
        updateResourceMushroom,
        deleteResourceMushroom,
        setResourceMicrogreen,
        updateResourceMicrogreen,
        deleteResourceMicrogreen,
        getContainerData,
        getSoilMediumData,
        getEnhancerData,
        getTaskSteps,
        getTaskData,
        savePlantInformation,
        savePlantInformationForUpdate,
        saveTransPlantStep,
        updateTransPlantStep,
        getTransPlantStep,
        deleteTransPlantStep,
        saveGrowingStep,
        updateGrowingStep,
        getGrowingStep,
        deleteGrowingStep,
        getCurrentPlant,
        getSupplierNames,
        getGraftingType,
        getCuttingSummary,
        setResourceCutting,
        getResourceCutting,
        getResourceCuttingList,
        updateResourceCutting,
        deleteResourceCutting,
        getVendorType,
        setVendorType,
        updateVendorType,
        deleteVendorType,
        getCostCategory,
        setCostCategory,
        updateCostCategory,
        deleteCostCategory,
        getProductTypeName,
        setProductTypeName,
        updateProductTypeName,
        deleteProductTypeName,
        setVendor,
        getVendorList,
        getVendor,
        updateVendor,
        deleteVendor,
        setIrrigationPlan,
        getIrrigationPlanList,
        getIrrigationPlan,
        updateIrrigationPlan,
        deleteIrrigationPlan,
        setIrrigationPlanForGrowingPlan,
        getIrrigationPlanForGrowingPlan,
        deleteIrrigationPlanForGrowingPlan,
        updateIrrigationPlanForGrowingPlan,
        setEnvironmentPlan,
        getEnvironmentPlanList,
        getEnvironmentPlan,
        updateEnvironmentPlan,
        deleteEnvironmentPlan,
        setEnvironmentPlanForGrowingPlan,
        getEnvironmentPlanForGrowingPlan,
        deleteEnvironmentPlanForGrowingPlan,
        updateEnvironmentPlanForGrowingPlan,
        setLightingPlan,
        getLightingPlanList,
        getLightingPlan,
        updateLightingPlan,
        deleteLightingPlan,
        setLightingPlanForGrowingPlan,
        getLightingPlanForGrowingPlan,
        deleteLightingPlanForGrowingPlan,
        updateLightingPlanForGrowingPlan,
        setNewLightingPlan,
        getNewLightingPlanList,
        getNewLightingPlan,
        updateNewLightingPlan,
        deleteNewLightingPlan,
        setNewLightingPlanForGrowingPlan,
        getNewLightingPlanForGrowingPlan,
        deleteNewLightingPlanForGrowingPlan,
        updateNewLightingPlanForGrowingPlan,
        getCurrentGrowingPlanStatus,
        finalizeGrowingPlan,
        updateGrowingPlan,
        getGrowingPlanList,
        deleteGrowingPlan,
        getRealPlantInformation,
        saveRealTransPlantStep,
        updateRealTransPlantStep,
        getRealTransPlantStep,
        deleteRealTransPlantStep,
        loadRealToTemp,
        saveRealGrowingStep,
        updateRealGrowingStep,
        getRealGrowingStep,
        deleteRealGrowingStep,
        updateRealLightingPlanForGrowingPlan,
        getRealLightingPlanForGrowingPlan,
        updateRealNewLightingPlanForGrowingPlan,
        getRealNewLightingPlanForGrowingPlan,
        updateRealIrrigationPlanForGrowingPlan,
        getRealIrrigationPlanForGrowingPlan,
        updateRealEnvironmentPlanForGrowingPlan,
        getRealEnvironmentPlanForGrowingPlan,
        getSeedSummary,
        getMushroomSummary,
        getMicrogreenSummary,
        getContainerType,
        createContainerType,
        updateContainerType,
        deleteContainerType,
        getContainer,
        createContainer,
        updateContainer,
        deleteContainer,
        getEnhancerType,
        createEnhancerType,
        updateEnhancerType,
        deleteEnhancerType,
        getEnhancer,
        createEnhancer,
        updateEnhancer,
        deleteEnhancer,
        getEnzymeType,
        setEnzymeType,
        updateEnzymeType,
        deleteEnzymeType,
        getFertilizerType,
        setFertilizerType,
        updateFertilizerType,
        deleteFertilizerType,
        getNutrientType,
        setNutrientType,
        updateNutrientType,
        deleteNutrientType,
        getPredatorInsectType,
        setPredatorInsectType,
        updatePredatorInsectType,
        deletePredatorInsectType,
        getUserList,
        deleteUser,
        enableUser,
        disableUser,
        getPrivateListsCount,
        getMushroomListByUser,
        getSeedListByUser,
        getMicrogreenListByUser,
        getCuttingListByUser,
        getLightingListByUser,
        getNewLightingListByUser,
        getEnvironmentListByUser,
        getIrrigationListByUser,
        getVendorListByUser,
        getGrowingPlanListByUser,
        getGrowingStrategyByUser,
        getAddressByUser,
        getUser,
        getLocationByUser,
        getMembershipHistoryByUser,
        getResourceSeedAdmin,
        getResourceMicrogreenAdmin,
        getResourceCuttingAdmin,
        getResourceMushroomAdmin,
        getEnvironmentPlanAdmin,
        getIrrigationPlanAdmin,
        getLightingPlanAdmin,
        getVendorAdmin,
        getGrowingPlanBasicAdmin,
        getGrowingPlanTransplantAdmin,
        getGrowingPlanGrowingStepAdmin,
        getGrowingPlanLightingAdmin,
        getGrowingPlanNewLightingAdmin,
        getGrowingPlanEnvironmentAdmin,
        getGrowingPlanIrrigationAdmin,
        getPrivateCropPlansCount,
        getAllAutoCropPlansByUser,
        getAllAdvancedCropPlansByUser,
        getAllManualCropPlansByUser,
        saveObservationNote,
        getObservationNoteList,
        updateObservationNote,
        deleteObservationNote,
        saveObservationStep,
        getObservationStepList,
        updateObservationStep,
        deleteObservationStep,
        getOneObservationNote,
        saveObservationLog,
        getObservationLogList,
        updateObservationLog,
        deleteObservationLog,
        getPlantGroup,
        setGrowingGroup,
        updateGrowingGroup,
        deleteGrowingGroup,
        getSeedingGroup,
        setSeedingGroup,
        updateSeedingGroup,
        getPublishListsCount,
        deleteSeedingGroup,
        getSeedPublishList,
        processSeedPublishRequest,
        getCuttingPublishList,
        getGrowingPlanPublishList,
        processGrowingPlanPublishRequest,
        cloneGrowingPlan,
        cloneSeed,
        cloneMushroom,
        cloneMicrogreen,
        cloneCutting,
        cloneLightingPlan,
        cloneNewLightingPlan,
        cloneEnvironmentPlan,
        cloneIrrigationPlan,
        sendPrivateNotification,
        sendPublicNotification,
        getAllSystemNotification,
        createSystemNotification,
        createStandardSystemNotification,
        updateStandardSystemNotification,
        archiveSystemNotification,
        deleteArchivedNotifications,
        retractNotifications,
        moveSystemNotificationToSent,
        activeStandardNotification,
        inactiveStandardNotification,
        changeAnnouncementOrder,
        marketGetAllSystemNotification,
        marketCreateSystemNotification,
        marketCreateStandardSystemNotification,
        marketUpdateStandardSystemNotification,
        marketArchiveSystemNotification,
        marketDeleteArchivedNotifications,
        marketRetractNotifications,
        marketMoveSystemNotificationToSent,
        marketActiveStandardNotification,
        marketInactiveStandardNotification,
        marketChangeAnnouncementOrder,
        getMembershipTrialDuration,
        updateMembershipTrialDuration,
        getStripeProductList,
        getStripeProduct,
        createStripeProduct,
        updateStripeProduct,
        deleteStripeProduct,
        getStripeTaxRateList,
        getStripeTaxRateHistory,
        getStripeTaxRateByCategory,
        createStripeTaxRate,
        updateStripeTaxRate,
        deleteStripeTaxRate,

        // marketplace
        getMarketUser,
        getMarketUserList,
        deleteMarketUser,
        makeFMMUser,
        enableMarketUser,
        disableMarketUser,
        verifyMarketUser,
        unverifyMarketUser,
        updateMarketSeller,
        updateMarketFMM,
        getMarketProductList,
        deleteMarketProduct,
        getMarketTaxRateList,
        updateMarketTaxRate,
        syncMarketTaxRate,
        getMarketDefaultTaxRateList,
        addMarketDefaultTaxRate,
        updateMarketDefaultTaxRate,
        getAllMarketCategory,
        getAllMarketOrderProducts,
        getAllTaxPaymentHistory,
        createTaxPayment,
        updateTaxPayment,
        deleteTaxPayment,
        getAllTaxCertificateData,
        createTaxCertificate,
        updateTaxCertificate,
        getAllCashSaleData,
        createCashSale,
        updateCashSale,
        deleteCashSale,
        getAllCardProcessingData,
        createCardProcessing,
        updateCardProcessing,
        deleteCardProcessing,
        importCardProcessingCsvData,
        getCountries,
        getStatesForCountry,
        getCitiesForState,
        getAllMarketSellersWithOrderInfo,
        getDetailInfoForSeller,
        getAllVendorPaymentHistory,
        createVendorPayment,
        updateVendorPayment,
        getMarketSystemSetting,
        cancelSellerOrder,
        getInvoiceDetailInfo,
        getAllProductReviews,
        deleteProductReview,
        deleteProductReply,
        getProductReviewsData,
        getTop10Products,
        getStripeFeeList,
        createStripeFee,
        editStripeFee,
        deleteStripeFee,
        getMarketplaceCommissionList,
        createMarketplaceCommission,
        editMarketplaceCommission,
        deleteMarketplaceCommission,
        getElectronicFeeTaxList,
        createElectronicFeeTax,
        editElectronicFeeTax,
        deleteElectronicFeeTax,
        getNewsList,
        createNews,
        updateNews,
        deleteNews,
        getNewsCategoryList,
        createNewsCategory,
        updateNewsCategory,
        deleteNewsCategory,
        updateUserDiscount,
        getAllInvestors,
        createInvestor,
        updateInvestor,
        deleteInvestor,
        getAllFarmersMarkets,
        deleteFarmersMarket,
        enableFarmersMarket,
        disableFarmersMarket,
        updateReviewerNotes,
        createReviewerNotes,
        deleteReviewerNotes,
        downloadMarketplaceNotiAttachment,
        getAllHistorySales,
        updateHistorySale
      }}
    >
      {children}
    </KhoraSetupContext.Provider>
  );
};

KhoraSetupProvider.propTypes = {
  children: PropTypes.node
};

export default KhoraSetupContext;
